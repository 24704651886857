import { MDBContainer, MDBRow, MDBCol } from 'mdbreact';

const About = () => {
  return (
    <MDBContainer className='my-5'>
      <MDBRow>
        <MDBCol>
        <div>
        <h1 className="text-center mb-5">About Us</h1>
            <hr style={{ borderTop: '1px solid #ccc', marginBottom: '1rem' }} />
            {/* <p>Welcome to <strong>PODtopia™ Media</strong>! We are a woman-owned company providing expertise in content creation and customized solutions to meet your unique needs!</p>
            <p>We offer a wide range of digital services, including content curation and editing of podcast scripts and websites,  personalized consulting, show hosting, strategies, custom production schedules, and more. Our goal is to help you make a positive lasting impression and engage your audience!</p>
            <p>Looking for the ideal team to amplify your brand? <a href="mailto:podtopiamedia@gmail.com">Contact PODtopia™ Media</a> today!</p>
        */}
            <div>
              <p>Welcome to <strong>PODtopia™ Media</strong>! We offer comprehensive services to elevate your content and enhance your brand's online presence.</p>
              <p>A trusted woman-owned company, we take great pride in delivering exceptional results. Our team of experts specializes in refining all forms of digital media content, including audio, text, and images, ensuring that your creative vision comes to life in the most captivating and polished way!</p>
              <p>We understand the importance of a competitive online presence and engagement with your target audience. That's why we offer personalized consulting services that cover a wide range of areas, from web content makeovers to podcasting processes.</p>
              <p>At PODtopia™ Media, we are dedicated to helping you leave a lasting positive impression on your audience that impactfully sets your brand apart.</p>
            </div>
        </div>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default About;
