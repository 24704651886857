import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ContactForm from './contact-form';
import ContactForm2 from './contact-form2';

const ElevateMeModal = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <div className="mt-1 secondary text-center">
      {/* <div className="">
        Your business is our business!
      </div> */}
      <Button id="elevateMe" color="primary" onClick={toggleModal} className="mx-auto mt-2">
      TURN UP THE VOLUME; CONTACT US TODAY!
      </Button>

      <Modal id='contact-modal' isOpen={isModalOpen} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Contact us for rates or questions.</ModalHeader>
        <ModalBody>
          <ContactForm2 />
        </ModalBody>
        {/* <ModalFooter>
          <Button color="secondary" onClick={toggleModal}>
            Close
          </Button>
        </ModalFooter> */}
      </Modal>
    </div>
  );
};

export default ElevateMeModal;
