// menu-bar.tsx

import React, { useState } from 'react';
import {
  MDBNavbar,
  MDBNavbarToggler,
  MDBIcon,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBContainer
} from 'mdb-react-ui-kit';
import { Link } from 'react-router-dom';

export default function MenuBar() {
  const [showNav, setShowNav] = useState(false);

  const toggleNav = () => {
    setShowNav(!showNav);
  };

  return (
    <MDBNavbar expand="lg" light bgColor="white">
      <MDBContainer fluid>
        <MDBNavbarToggler
          aria-controls="navbarExample01"
          aria-expanded={false}
          aria-label="Toggle navigation"
          onClick={toggleNav}
        >
          <MDBIcon fas icon="bars" />
        </MDBNavbarToggler>
        <div
          className={`collapse navbar-collapse ${showNav ? 'show' : ''}`}
          id="navbarExample01"
        >
          <MDBNavbarNav right className="mb-2 mb-lg-0">
            <MDBNavbarItem>
              <Link to="/home">
                <MDBNavbarLink>
                  Home
                </MDBNavbarLink>
              </Link>
            </MDBNavbarItem>
            <MDBNavbarItem>
              <Link to="/about">
                <MDBNavbarLink>
                  About
                </MDBNavbarLink>
              </Link>
            </MDBNavbarItem>
            <MDBNavbarItem>
              <Link to="/services">
                <MDBNavbarLink>
                  Services
                </MDBNavbarLink>
              </Link>
            </MDBNavbarItem>
            <MDBNavbarItem>
              <Link to="/CEO">
                <MDBNavbarLink>
                  CEO
                </MDBNavbarLink>
              </Link>
            </MDBNavbarItem>
          </MDBNavbarNav>
        </div>
      </MDBContainer>
    </MDBNavbar>
  );
}
