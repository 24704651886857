import React from "react";
import { Container, Row, Col, Card, CardBody, CardImg } from "reactstrap";
import ceoKimKim from '../assets/images/ceo-kimkim.webp';

const MeetTheCEO = () => {
  return (
    <Container className="py-5">
      <h1 className="text-center mb-5">Meet the Founder & CEO</h1>
      <Row>
        <Col lg={4} className="mb-4">
          <Card>
            <CardImg top src={ceoKimKim} alt="Kimberly Evering" />
            <CardBody> 
              <p><strong>Kimberly Evering</strong> has over 20 years of experience shaping clear messages across corporate, educational, and governmental sectors, earning her numerous accolades and awards. Drawing from her passion for crafting compelling content that resonates with her audience, Kimberly is also a charismatic podcast host and seasoned speaker renowned for her dynamic communication skills and engaging storytelling.</p>

              <p>Her multifaceted background includes a stint as a DJ at an alternative rock radio station and serving as a news service reporter during the 2010 and 2014 Winter Olympic Games. In addition, Kimberly holds a master's degree in digital journalism from American University, further solidifying her expertise in the field.</p>

              <p><strong><i>Kimberly is a sought-after keynote speaker specializing in popular topics such as podcast interviewing techniques, the value of podswapping, and the power of voice through storytelling. Her unique blend of digital media expertise and captivating presence make her an ideal choice for speaking engagements at podcasting events, where she is sure to inspire and enlighten attendees!</i></strong></p>
            </CardBody>
          </Card>
        </Col>
        <Col lg={8}>
          <Card className="mb-4">
            <CardBody>
              <h3>Credentials</h3>
              <ul>
              <li><a href="https://galotgo.com/gal-on-the-go-unplugged-podcast/" target="_blank" rel="noopener noreferrer"><strong>Gal on the Go Unplugged™</strong></a>, Podcast Host</li>
              <li><a href="https://www.daveyawards.com/" target="_blank" rel="noopener noreferrer"><strong>DAVEY Awards, 2022</strong></a>, Silver Award for Best in General Interview | Talk Show Podcast</li>
                <li><a href="https://www.aiva.org/" target="_blank" rel="noopener noreferrer"><strong>The Academy of Interactive &amp; Visual Arts (AIVA)</strong></a>, Board Member & Juror</li>
                <li><a href="https://www.thepodcastacademy.com/" target="_blank" rel="noopener noreferrer"><strong>The Podcast Academy (TPA)</strong></a>, Member</li>
                <li><a href="https://usadailypost.com/2023/02/17/kimberly-everings-commentary-feature-on-the-price-of-business-digital-network/" target="_blank" rel="noopener noreferrer"><strong>Price of Business Digital Network</strong></a>, Nationally Syndicated Lifestyle Commentator of <i>The Balanced Life With Gal on the Go</i> Series</li>
                <li><strong>International Olympic Committee, News Service Reporter</strong></li>
                <ul>
                  <li>U.S. Ambassador 2014 Winter Games | Hockey</li>
                  <li>U.S. Ambassador 2010 Winter Games | Alpine Skiing</li>
                </ul>
              </ul>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default MeetTheCEO;
