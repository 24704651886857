import React from 'react';
import logo from './logo.svg';
import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/home';
import About from './components/about';
import MenuBar from './components/menu-bar';
import Services from './components/services';
import MeetTheCEO from './components/ceo';
import './App.css';
import './assets/styles/main.css'
import { FaEnvelope, FaPhone, FaInstagram } from 'react-icons/fa';
import { Container, Row, Col } from 'reactstrap';

function App() {
  return (
    <div className="wrapper">
      <Router>
        <MenuBar />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/home' element={<Home />} />
          <Route path='/services' element={<Services />} />
          <Route path='/about' element={<About />} />
          <Route path='/ceo' element={<MeetTheCEO />} />
        </Routes>
      </Router>
      <div className="footer">
        <div className="d-flex justify-content-center align-items-center mb-md-0">
          <strong>PODtopia™ Media</strong>
        </div>
        <Container >
          <Row className="justify-content-center">
            <Col md="4" className="d-flex justify-content-center mb-1 mb-md-0">
              <a href="mailto:podtopiamedia@gmail.com">
                <FaEnvelope className="mr-1" />
                podtopiamedia@gmail.com
              </a>
            </Col>
            <Col md="4" className="d-flex justify-content-center mb-1 mb-md-0">
              <a href="tel:+17168664904">
                <FaPhone className="mr-1" />
                +1 (716) 866-4904
              </a>
            </Col>
            <Col md="4" className="d-flex justify-content-center mb-1 mb-md-0">
              <a href="https://www.instagram.com/podtopiamedia/">
                <FaInstagram className="mr-1" />
                @podtopiamedia
              </a>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default App;