import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import { EMAILJS_USER_ID, EMAILJS_TEMPLATE_ID, EMAILJS_SERVICE_ID } from '../assets/config/email-config';

import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBBtn,
  MDBTextArea
} from 'mdb-react-ui-kit';

import '../assets/styles/base.css';

// set up EmailJS service
const service_id = EMAILJS_SERVICE_ID;
const template_id = EMAILJS_TEMPLATE_ID;
const user_id = EMAILJS_USER_ID;

emailjs.init(user_id);

export default function ContactForm() {

    const [isSubmitted, setIsSubmitted] = useState(false); // Add new state variable

  const [formState, setFormState] = useState({
    from_name: '',
    from_email: '',
    message: '',
    to_name: "Kimberly"
  });

  const handleInputChange = (event: { target: { name: any; value: any; }; }) => {
    const { name, value } = event.target;
    setFormState({ ...formState, [name]: value });
  };

  const sendEmail = () => {
    emailjs.send(service_id, template_id, formState)
      .then((result) => {
        console.log(result.text);
        // alert('Email sent successfully');
        setIsSubmitted(true); // Update state variable on successful submission
      }, (error) => {
        console.log(error.text);
        alert('Error sending email');
      });
  };

  const handleSubmit = (event: { preventDefault: () => void; }) => {
    event.preventDefault();
    sendEmail();
  };

  return (
    <MDBContainer>
      <MDBRow className='justify-content-center'>
        <MDBCol md='10'>
                       {/* Render form or message based on submission status */}
                       {!isSubmitted ? (
          <form className='contact-form' onSubmit={handleSubmit}>
            <div className='contact-form-group'>
              <MDBInput
                label='Your name'
                name='from_name'
                value={formState.from_name}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className='contact-form-group'>
              <MDBInput
                label='Your email'
                type='email'
                name='from_email'
                value={formState.from_email}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className='contact-form-group'>
              <MDBTextArea
                className='contact-input-msg'
                label='Your message'
                name='message'
                rows={5}
                value={formState.message}
                onChange={handleInputChange}
                required
              />
            </div>
            <MDBBtn type='submit' className='contact-send-btn'>
              Send
            </MDBBtn>
          </form>
          ) : (
            <div className='contact-form-submitted'>
              <p>Thank you for your message. We will get back to you soon.</p>
            </div>
          )}
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
}
