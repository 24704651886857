import { Col, Container, Row } from 'reactstrap';
import { FaEnvelope, FaPhone, FaInstagram } from 'react-icons/fa';
import mainImg from '../assets/images/banner-full.png';
import ElevateMeModal from './elevate-me';
import '../assets/styles/base.css';

export default function Home() {
  return (
    
    <div>
      <header style={{ paddingLeft: 0 }}>
        <div id='testDiv' className="p-5 mt-5 text-center banner-text">
          <div>
            <img src={mainImg} alt="banner" className="img-fluid banner-image" />
          </div>
          <div className="p-1 banner secondary">Experts in amplifying the quality of your content!</div>
        </div>
      </header>
      {/* <footer style={{ backgroundColor: '#e6e6e6', color: 'black', textAlign: 'center', padding: '20px' }}>
        <div className="d-flex justify-content-center align-items-center mb-3">
          <strong>Digitally ELEVATED Media</strong>
        </div>
        <Container>
          <Row className="justify-content-center">
            <Col md="4" className="d-flex justify-content-center mb-3 mb-md-0">
              <a href="mailto:podtopiamedia@gmail.com">
                <FaEnvelope className="mr-2" />
                podtopiamedia@gmail.com
              </a>
            </Col>
            <Col md="4" className="d-flex justify-content-center mb-3 mb-md-0">
              <a href="tel:+17168664904">
                <FaPhone className="mr-2" />
                +1 (716) 866-4904
              </a>
            </Col>
            <Col md="4" className="d-flex justify-content-center mb-3 mb-md-0">
              <a href="https://www.instagram.com/podtopiamedia/">
                <FaInstagram className="mr-2" />
                @podtopiamedia
              </a>
            </Col>
          </Row>
        </Container>
      </footer> */}
    </div>
  );
}
