import React from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import { FaPencilAlt, FaImage, FaPodcast, FaKeyboard, FaList } from "react-icons/fa";
import ElevateMeModal from "./elevate-me";

import "../assets/styles/base.css";
//import image
import stockImg from "../assets/images/stock-img-key-head-mic.jpg";

const Services = () => {
  return (
    <Container className="py-5">
      <div>
        <h1 className="text-center mb-5">Our Services</h1>
      </div>
      <Row>
        <Col lg={4} md={6} className="mb-4">
          <Card>
            <CardBody className="text-center">
              <FaPodcast size={40} className="mb-3" />
              <h4>Podcasting</h4>
              <p className="text-muted services-desc">We focus on your specific theme or topic in all formats, including interviews, storytelling, educational content, news updates, or discussions.</p>
              <ul className="text-muted services-desc">
                <li>Podcast Consulting</li>
                <li>Interview Technique & Question Development Coaching</li>
                <li>Production Scheduling</li>
                <li>Show Hosting</li>
                <li>Scripts</li>
              </ul>
            </CardBody>
          </Card>
        </Col>
        <Col lg={4} md={6} className="mb-4">
          <Card>
            <CardBody className="text-center">
              <FaKeyboard size={40} className="mb-3" />
              <h4>Writing</h4>
              <p className="text-muted services-desc">We offer original content curation to help your brand stand out on all your digital media platforms.</p>
              <ul className="text-muted services-desc">
                <li>Scripts</li>
                <li>Web Content</li>
                <li>Social Media Content</li>
              </ul>
            </CardBody>
          </Card>
        </Col>
        <Col lg={4} md={6} className="mb-4">
          <Card>
            <CardBody className="text-center">
              <FaList size={40} className="mb-3" />
              <h4>Editing</h4>
              <p className="text-muted services-desc">We refine your text and audio to improve your messaging.</p>
              <ul className="text-muted services-desc">
                <li>Scripts</li>
                <li>Audio Recordings</li>
                <li>Web Content</li>
                <li>Social Media Content</li>
                <li>Copywriting and Proofreading</li>
                <li>Photo Refinement</li>
              </ul>
            </CardBody>
          </Card>
        </Col>
      </Row>
      {/* <Row className="justify-content-center">
        <Col lg={4} md={6} className="mb-4">
            <ElevateMeModal/>
        </Col>
      </Row> */}
      <Row className="justify-content-center">
        <Col lg={4} md={6} className="mb-4">
          <ElevateMeModal />
        </Col>
      </Row>
      <div className="text-center mb-5">
        <img src={stockImg} alt="" className="img-fluid" />
      </div>
    </Container>
  );
};

export default Services;
